import React from "react";
import Helmet from "react-helmet";

import Layout from "components/Layout";
import Container from "components/Container";

const ServicesPage = () => {
  return (
    <Layout pageName="services">
      <Helmet>
        <title>Services</title>
      </Helmet>
      <Container>
        <h1>Treatments &amp; Pricelist</h1>
    
    
    <div className="row">
          <div className="column">
            <p>Reflexology</p>
            <p>Advanced Facial Reflexology</p>
            
          </div>
          <div className="column yellow-price">
            <p>€40.00</p>
            <p>€40.00</p>
          </div>
        </div>
    

        <div className="row">
          <div className="column">
            
            <p>Caim Bespoke Facial
            <br />
              <span className="grey-text">
                Skin consultation included on first treatment.
                May include one/some of the following :
                Ultrasonic Cleanse, Facial Reflexology, Mesotherapy, High Frequency, Dual Injector,
                LED light therapy, Dermaplane &amp; more
              </span>
            </p>
          </div>
          <div className="column yellow-price">
            
            <p>€65.00</p>
          </div>
        </div>
    
    <div className="row">
          <div className="column">
            <p>Brow Tint &amp; Tidy</p>
            <p>Brow Tint &amp; Tidy
            <br />
              <span className="grey-text">
                Including Lash Tint
              </span>
            </p>
          </div>
          <div className="column yellow-price">
            <p>€18.00</p>
            <p>€20.00</p>
          </div>
        </div>

        <div className="row">
          <div className="column">
            <p>Henna Brow &amp; Shape</p>
            <p>Lamination Brow</p>
            <p>Facial Waxing (Price Per Area)</p>
          </div>
          <div className="column yellow-price">
            <p>€30.00</p>
            <p>€30.00</p>
            <p>€5.00</p>
          </div>
        </div>

      </Container>
    </Layout>
  );
};

export default ServicesPage;
